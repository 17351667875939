import React from 'react';

import { Link } from 'gatsby';

import copy from 'data/copy';

import { Wrapper } from './CategoriesGrid.styles';

const CategoriesGrid = (props) => {
  const { data, lang } = props;

  return (
    <Wrapper>
      {data.edges
        .filter((e) => {
          return e.node.language?.code === lang;
        })
        .map((e, index) => {
          const {
            node: {
              wpChildren,
              slug: mainCategorySlug,
              description,
              name,
              parentId,
            },
          } = e;

          if ((!wpChildren.nodes || !wpChildren.nodes.length) && !parentId) {
            return (
              <Link
                key={index + '-' + mainCategorySlug}
                className="item"
                id={`img${mainCategorySlug}`}
                to={`/${copy.query.products[lang]}?${copy.query.category[lang]}=${mainCategorySlug}`}
              >
                <div className="img">
                  <img
                    src={description}
                    alt={name}
                    onLoad={(e) => {
                      document
                        ?.querySelector(`#img${mainCategorySlug}`)
                        ?.classList.add('large');
                    }}
                  />
                </div>
                <p>{name}</p>
              </Link>
            );
          }

          return wpChildren.nodes
            .filter((e) => {
              console.log(e);
              return e.language?.code === lang;
            })
            .map((subitem, childIndex) => {
              const { description, name, slug } = subitem;

              return (
                <Link
                  key={index + '-' + childIndex}
                  className="item"
                  id={`img${slug}`}
                  to={`/${copy.query.products[lang]}?${copy.query.category[lang]}=${mainCategorySlug}&${copy.query.subcategory[lang]}=${slug}`}
                >
                  <div className="img">
                    <img
                      src={description}
                      alt={name}
                      onLoad={(e) => {
                        document
                          ?.querySelector(`#img${slug}`)
                          ?.classList.add('large');
                      }}
                    />
                  </div>
                  <p>{e.node.name} - {name}</p>
                </Link>
              );
            });
        })}
    </Wrapper>
  );
};

export default CategoriesGrid;
