import styled from 'styled-components';

import { space } from 'theme/sizes';
import { black, white, blue } from 'theme/colors';

import { toRem, breakpoints } from 'utils/mixins';
import { p, h4 } from 'theme/typography';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  .filters {
    width: 100%;
    padding: ${space.xxs};
    display: flex;
    flex-direction: column;

    &__title {
      ${h4}
    }

    &__list {
      margin-top: ${space.xxs};

      &--insect {
        margin-left: ${space.xs};
        overflow: hidden;
        max-block-size: 0px;
        transition: all 0.75s;

        .filters__selected {
          width: ${space.xs};
          height: ${space.xs};
          font-size: 8px;
        }
      }
    }

    &__item {
      margin-right: ${space.xxs};
      margin-bottom: ${space.xxs};
    }

    &__inner-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__selected {
      width: ${space.s};
      height: ${space.s};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${space.xxs};
      border: 1px solid ${black.default};
      border-radius: ${space.tiny};
      background-color: ${white.darker};

      * {
        display: none;
      }

      &--active {
        background-color: ${white.grey};
        * {
          display: block;
        }
      }
    }

    &__btn {
      ${p}
      display: flex;
      align-items: center;
      font-weight: 300;
      font-size: ${toRem(13)};
      line-height: ${toRem(17)};
      letter-spacing: 0.1px;
      text-align: left;

      span {
        font-size: ${toRem(9)};
        line-height: ${toRem(17)};
        margin-left: ${space.xxs};
        transform: translateY(2px);
        pointer-events: none;
      }
    }

    &__apply {
      background-color: ${blue.default};
      color: ${white.default};
      padding: ${space.xs};
    }
  }

  @media ${breakpoints.laptop} {
    position: -webkit-sticky; /* Safari */
    top: ${space.header};
    margin: auto;
    position: sticky;
    margin: auto;

    .filters {
    }
  }
`;
