import React from 'react';

import { motion } from 'framer-motion';
import { graphql } from 'gatsby';

import ProductsGrid from 'components/productsGrid';
import ContactForm from 'components/contact-form';
import CategoriesGrid from 'components/categoriesGrid';

import { grey } from 'theme/colors';
import { pageAnims } from 'theme/animations';

const TemplateProdutos = (props) => {
  //PROPS
  const {
    lang,
    data: {
      page: {
        products: { introText },
      },
      categories,
    },
    location,
    dimensions,
  } = props;
  //PROPS

  const params = new URLSearchParams(location.search);

  if (params.has('ver-todos')) {
    return (
      <>
        <motion.div
          style={{
            background: grey.default,
          }}
          variants={pageAnims}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <CategoriesGrid data={categories} lang={lang} />
        </motion.div>
        <ContactForm lang={lang} />
      </>
    );
  }

  return (
    <>
      <motion.div
        style={{
          background: grey.default,
          minHeight: '100vh',
        }}
        variants={pageAnims}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <ProductsGrid
          lang={lang}
          hasFilters
          location={location}
          dimensions={dimensions}
        />
      </motion.div>
      <ContactForm lang={lang} />
    </>
  );
};

export const query = graphql`
  query Produtos($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      products {
        introText
      }
    }
    categories: allWpCategoriaProd {
      edges {
        node {
          name
          slug
          description
          count
          parentId
          language {
            code
          }
          wpChildren {
            nodes {
              slug
              parentId
              name
              description
              count
              language {
                code
              }
            }
          }
        }
      }
    }
  }
`;

export default TemplateProdutos;
