const getParam = ({ param, url }) => {
  const params = new URLSearchParams(url);

  return params.getAll(param);
};

const hasParam = ({ param, url }) => {
  const params = new URLSearchParams(url);

  return params.has(param);
};

const hasParamValue = ({ param, value, url }) => {
  const currentParams = getParam({ param, url });
  return currentParams.includes(value);
};

const setParam = ({ param, value, url }) => {
  const params = new URLSearchParams(url);

  params.append(param, value);
  return params.toString();
};

const deleteParam = ({ param, url }) => {
  const params = new URLSearchParams(url);
  params.delete(param);
  return params.toString();
};

const deleteParamValue = ({ param, value, url }) => {
  const params = new URLSearchParams(url);

  const currentParams = getParam({ param, url });
  params.delete(param);

  if (value) {
    currentParams
      .filter((e) => e !== value)
      .forEach((e) => params.append(param, e));
  }

  return params.toString();
};

export {
  getParam,
  hasParam,
  hasParamValue,
  setParam,
  deleteParam,
  deleteParamValue,
};
