import React, { useCallback, useEffect, useState } from 'react';

import { graphql, navigate, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import copy from 'data/copy';

import { deleteParamValue, hasParamValue, setParam } from 'utils/params-helper';

import { Wrapper } from './Filters.styles';

const Filters = (props) => {
  //PROPS
  const {
    lang,
    toggleFilters,
    location: { search, pathname },
  } = props;
  //PROPS

  const [selected, setSelected] = useState();

  //STATES
  const data = useStaticQuery(
    graphql`
      query Filters {
        allWpCategoriaProd {
          edges {
            node {
              name
              id
              slug
              description
              count
              parentId
              category {
                order
              }
              language {
                code
              }
              wpChildren {
                nodes {
                  slug
                  name
                  id
                  description
                  count
                  parentId
                  category {
                    order
                  }
                  language {
                    code
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const mainCategories = data.allWpCategoriaProd.edges
    .filter((e) => !e.node.parentId && e.node.language.code === lang)
    .sort((a, b) => a.node.category?.order - b.node.category?.order);
  //STATES

  function removeTrailingSlash(str) {
    return str.replace(/\/+$/, '');
  }

  const updateFilters = useCallback(
    (type, string) => {
      let url = selected;

      const hasValue = hasParamValue({
        param: type,
        value: string,
        url,
      });

      if (type === copy.query.category[lang]) {
        const childrenNodes =
          type === copy.query.category[lang]
            ? mainCategories.filter((e) => e.node.slug === string)[0].node
                .wpChildren.nodes
            : [];

        if (hasValue) {
          childrenNodes.forEach((e) => {
            url = deleteParamValue({
              param: copy.query.subcategory[lang],
              value: e.slug,
              url,
            });
          });
        } else {
          childrenNodes.forEach((e) => {
            url = setParam({
              param: copy.query.subcategory[lang],
              value: e.slug,
              url,
            });
          });
        }
      }

      if (hasValue) {
        url = deleteParamValue({ param: type, value: string, url });
      } else {
        url = setParam({ param: type, value: string, url });
      }
      url = deleteParamValue({ param: 'pagina', url });
      setSelected(url);
    },
    [selected]
  );

  useEffect(() => {
    setSelected(search);
  }, [search]);

  const applyFilters = () => {
    toggleFilters?.(false);
    navigate(
      removeTrailingSlash(pathname) +
        (selected.toString().length ? '?' + selected.toString() : '')
    );
  };

  const isSelected = (slug, type, children) => {
    if (children?.length) {
      return (
        children.some((subnode) =>
          selected?.includes('subcategoria=' + subnode.slug)
        ) || selected?.includes(type + '=' + slug)
      );
    }

    return selected?.includes(type + '=' + slug);
  };

  return (
    <Wrapper>
      <div className="filters">
        <h4 className="filters__title">{copy.filters[lang]}:</h4>
        <ul className="filters__list">
          {mainCategories.map(({ node }, index) => {
            return (
              <li key={index} className={'filters__item'}>
                <div className="filters__inner-item">
                  <button
                    onClick={() => {
                      updateFilters(copy.query.category[lang], node.slug);
                    }}
                  >
                    <span
                      className={
                        isSelected(
                          node.slug,
                          'categoria',
                          node.wpChildren.nodes
                        )
                          ? 'filters__selected filters__selected--active'
                          : 'filters__selected'
                      }
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  </button>
                  <button
                    onClick={(e) => {
                      if (node.wpChildren.nodes.length) {
                        const height = node.wpChildren.nodes.length * 25;
                        const isOpen = !!(
                          e.target.parentElement.parentElement.parentElement.querySelector(
                            '.filters__list'
                          ).style['max-block-size'] !== '0px' &&
                          e.target.parentElement.parentElement.parentElement.querySelector(
                            '.filters__list'
                          ).style['max-block-size'] !== ''
                        );

                        if (isOpen) {
                          e.target.parentElement.parentElement.parentElement.querySelector(
                            '.filters__list'
                          ).style['max-block-size'] = 0 + 'px';
                        } else {
                          e.target.parentElement.parentElement.parentElement.querySelector(
                            '.filters__list'
                          ).style['max-block-size'] = height + 'px';
                        }
                      } else {
                        updateFilters(copy.query.category[lang], node.slug);
                      }
                    }}
                  >
                    <span className="filters__btn">
                      {node.name} ({node.count ?? 0}){' '}
                      {!!node.wpChildren.nodes.length && (
                        <span>
                          <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                      )}
                    </span>
                  </button>
                </div>
                <ul className="filters__list filters__list--insect">
                  {node.wpChildren.nodes.map((subnode, index) => {
                    return (
                      <li key={index} className={'filters__item'}>
                        <button
                          className="filters__inner-item"
                          onClick={() => {
                            updateFilters(
                              copy.query.subcategory[lang],
                              subnode.slug,
                              node.slug
                            );
                          }}
                        >
                          <span
                            className={
                              isSelected(subnode.slug, 'subcategoria')
                                ? 'filters__selected filters__selected--active'
                                : 'filters__selected'
                            }
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className="filters__btn">
                            {subnode.name} ({subnode.count ?? 0}){' '}
                          </span>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
        <button onClick={applyFilters} className="filters__apply">
          {copy.apply[lang]}
        </button>
      </div>
    </Wrapper>
  );
};

export default Filters;
