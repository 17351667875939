import styled from 'styled-components';

import { space } from 'theme/sizes';
import { white, blue, grey } from 'theme/colors';
import { breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: ${space.s};
  margin-bottom: ${space.s};

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 ${space.xxs};
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 ${space.xs};

    li {
      margin: ${space.tiny};

      button {
        height: ${space.m};
        width: ${space.m};
        border-radius: 100%;
        background: ${grey.default};

        &.active {
          color: ${white.default};
          background: ${blue.default};
        }
      }
    }
  }
`;
