import styled from 'styled-components';

import { container, space } from 'theme/sizes';
import { breakpoints, toRem } from 'utils/mixins';
import { grey, white } from 'theme/colors';

export const Wrapper = styled.div`
  position: relative;
  padding: ${space.xs};
  background-color: white;
  width: 100%;
  min-height: 100vh;

  .content-container {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: stretch;
    max-width: ${container.default};
    width: 100%;
    flex-wrap: wrap;
  }

  .grid-filters {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    &__button {
      font-size: ${space.s};
      line-height: 1;
      padding: ${space.xs};
      display: block;
      justify-self: flex-end;
      background-color: ${grey.default};
    }

    &__container {
      width: 100vw;
      height: 100vh;
      padding: ${space.xs};
      background-color: ${white.default};
      align-items: flex-end;
      position: fixed;
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 3;
    }
  }

  .grid {
    position: relative;
    min-height: 360px;
    width: 80%;
  }

  .items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: ${space.xs};
    grid-row-gap: ${space.xs};
    width: 100%;
  }

  @media ${breakpoints.tablet} {
    margin: ${(props) => (props.hasSpacing ? space.xxl : space.l)} auto;

    .items {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media ${breakpoints.laptop} {
    display: flex;
    justify-content: center;

    .items {
      grid-template-columns: repeat(3, 1fr);
    }

    .grid-filters {
      max-width: ${toRem(260)};
    }
  }
`;
