import React from 'react';

import { BsFilterRight, BsX } from 'react-icons/bs';

import Pagination from 'components/pagination';
import ProductThumb from 'components/productThumb';
import Filters from 'components/filters';

import copy from 'data/copy';

import { Loading } from '../loading';

import { useFilters, useProducts } from './handlers';

import { Wrapper } from './ProductsGrid.styles';

const ProductsGrid = (props) => {
  //PROPS
  const {
    hasFilters = false,
    lang,
    data: selectedData,
    spacing = false,
    location,
    dimensions,
  } = props;
  //PROPS

  const { filters, showFilters, toggleFilters } = useFilters({ location });
  const { items, totalItems, isLoading } = useProducts({
    filters,
    selectedData,
    showFilters,
  });

  return (
    <Wrapper hasSpacing={spacing}>
      {hasFilters && (
        <div className="grid-filters">
          {dimensions.width > 1024 ? (
            <Filters lang={lang} filters={filters} location={props.location} />
          ) : (
            <>
              <button
                className="grid-filters__button"
                onClick={() => toggleFilters(true)}
              >
                <BsFilterRight />
              </button>
              {showFilters && (
                <div className="grid-filters__container">
                  <button
                    className="grid-filters__button"
                    onClick={() => toggleFilters(false)}
                  >
                    <BsX />
                  </button>
                  <Filters
                    lang={lang}
                    filters={filters}
                    location={props.location}
                    toggleFilters={toggleFilters}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
      <div className="content-container">
        <div className="grid">
          {isLoading ? (
            <Loading absolute />
          ) : !items?.length ? (
            <>
              <p>{copy.noresults[lang]}</p>
            </>
          ) : (
            <div className="items">
              {items.map((product, index) => {
                return (
                  <ProductThumb
                    data={{ ...product.product, title: product.title }}
                    slug={product.slug}
                    uri={product.seo.slug}
                    key={index}
                  />
                );
              })}
            </div>
          )}
        </div>
        {!!items.length && !isLoading && (
          <Pagination
            totalItems={totalItems}
            location={location}
            lang={lang}
            currentPage={filters.pagination}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default ProductsGrid;
