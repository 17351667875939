import styled from 'styled-components';

import { space } from 'theme/sizes';
import { toRem, breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(100% / 2));
  grid-auto-rows: ${space.xxs};
  justify-content: center;

  .item {
    padding: ${space.xs};
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    grid-row-end: span 33;

    p {
      width: 100%;
      text-align: center;
      margin: 0;
      display: block;
    }

    .img {
      display: block;
      position: relative;
      flex: 1;

      img {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  @media ${breakpoints.laptop} {
    grid-template-columns: repeat(auto-fill, calc(100% / 6));
  }
`;
